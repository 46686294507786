import React, { useState,useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Autocomplete from 'react-google-autocomplete';
import {Hotelapitoken,ApiEndPoint} from '../GlobalData/GlobalData';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import Select from 'react-select'
import {
    faGlobe,
    faCalendar,
  } from '@fortawesome/free-solid-svg-icons';
  import { ToastContainer, toast } from 'react-toastify';
import Axios from "axios";
import moment from "moment";
import Loader from "../Loading/Loader";
import { useNavigate } from 'react-router-dom';
import { SearchToursDetail } from '../../Redux/Actions/actions';

function ModifyPackageSearch() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    var endpoint=ApiEndPoint();
    
    const [loading, setLoading] = useState(false);
    const [umrahPackageId,setUmrahPackageId]=useState('');
    const [allcateogries, setAllCateogries] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [tourdestination, setTourdestination] = useState(null);
    const [selectedTourDate, setSelectedTourDate] = useState(null);
    useEffect(()=>{
        GetPackages();
    },[]);

    const GetPackages= async()=>{
        var token=Hotelapitoken();
        const fullURL = window.location.href;
        var data={
          'token':token,
          'currentURL':fullURL,
          'limit':6
        };
        try {
           const response = await Axios.post(endpoint+'/api/get_website_index_data',data, {
             headers: {
               "Access-Control-Allow-Origin": "*",
             } ,
             
           });
           setUmrahPackageId(response.data.all_cateogries[0].id);
           setAllCateogries(response.data.all_cateogries);
         } catch (error) {
          
           console.error('Error:', error);
         };
    
       };

    const handleSelectUmrahPackage=event=>{
        setUmrahPackageId(event.target.value);
      };
      const handlePlaceSelected = (place) => {
        setTourdestination(place);
      };
      const ToursDestination = (e) => {
        setInputValue(e.target.value);
      };
      const handleTourDateChange = date => {
        setSelectedTourDate(date)
      };
      const ToursSearchbtn=async()=>{
        if(umrahPackageId !=='' && selectedTourDate !==null){
        var token=Hotelapitoken();
        
        var data={
          'token':token,
          'category':umrahPackageId,
          'start_date':moment(selectedTourDate).format('YYYY-MM-DD'),
          'search_for':'tour'
        };
       
        setLoading(true)
       try {
           const response = await Axios.post(endpoint+'/api/search_pakages',data, {
             headers: {
               "Access-Control-Allow-Origin": "*",
             } ,
             
           })
           setLoading(false)
          //  console.log(response.data)
           dispatch(SearchToursDetail(response.data));
           navigate('/search-tour');
         } catch (error) {
            setLoading(false);
           console.error('Error:', error);
         };
        }else{
            toast.error('Please Select Data Again!', {
                position: 'top-right', // You can customize the position
                autoClose: 3000, // Auto close the notification after 3 seconds
              });
        }
    
      };
    return (
        <>
        {loading && (
            <Loader/>
        )}
        <ToastContainer/>
         <div className='px-4 py-1'>
            <div className="row">
                <div width="1,,0.5,,0.5" class="Box-pcln-design-system__sc-n9h3nv-0 fPtMyk" style={{width:'30%'}}>
                  <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                    <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                        <div width="1" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="flights.0.startLocation-typeahead-downshift-container-label" aria-controls="typeahead-dropdown" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw" style={{position: 'relative'}}>
                        <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 sc-20db91c2-0 bXyETw gSCCYN">
                            <div color="background.lightest" class="Box-pcln-design-system__sc-n9h3nv-0 hhIGyZ">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                                <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 kFfTeO">
                                <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 FormField__DisableableBox-pcln-design-system__sc-tx1s9e-0 WjkcY">
                                        <label width="auto" for="flights.0.startLocation-typeahead-downshift-container-input" id="flights.0.startLocation-typeahead-downshift-container-label" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="Label-pcln-design-system__sc-7sa88g-0 ezPCvJ"
                                            style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '0', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Departing from?</label>
                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK" style={{borderRadius:'12px'}}>
                                            <div data-testid="icon-field-prefix-icons" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 danbLM brHOWu">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Search__SvgSearch-sc-1osw7jr-0 kHgnEk">
                                                <path d="M15.5 14h-.8l-.3-.3c1-1.1 1.6-2.6 1.6-4.2C16 5.9 13.1 3 9.5 3S3 5.9 3 9.5 5.9 16 9.5 16c1.6 0 3.1-.6 4.2-1.6l.3.3v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z"></path>
                                            </svg>
                                            </div>
                                            <select id="flights.0.startLocation-typeahead-downshift-container-input" data-testid="flights.0.startLocation-typeahead-input" aria-autocomplete="list" aria-labelledby="flights.0.startLocation-typeahead-downshift-container-label" 
                                                placeholder='Select Package' font-size="2,,1" class="Input__StyledInput-pcln-design-system__sc-7wuyrc-0 VtYfX" 
                                                value={umrahPackageId}  onChange={handleSelectUmrahPackage}>
                                                {allcateogries.map((item, index) => (
                                                        <option key={item.id} selected={index === 0} value={item.id}>{item.title}</option>
                                                    ))}
                                            </select>
                                                {/* <select
                                                    value={umrahPackageId}
                                                    onChange={handleSelectUmrahPackage}
                                                    className='form-select select-styling'
                                                    aria-label='Default select example'
                                                >
                                                    {allcateogries.map((item, index) => (
                                                        <option key={item.id} selected={index === 0} value={item.id}>{item.title}</option>
                                                    ))}
                                                </select> */}
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div width="1,,0.5,,0.5" class="Box-pcln-design-system__sc-n9h3nv-0 fPtMyk" style={{width:'25%'}}>
                    <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                        <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                        <div class="sc-gEvEer iTwuwh">
                            <div width="1" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="endLocation-typeahead-downshift-container-label" aria-controls="typeahead-dropdown" class="sc-gEvEer dsJkWl" style={{position: 'relative'}}>
                            <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                <div color="background.lightest" class="sc-gEvEer hILmdS">
                                    <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                    <div width="1" class="sc-gEvEer SjeQr">
                                        <div class="sc-gEvEer PJepm">
                                        <div class="sc-gEvEer sc-uVWWZ kPRUfN jGcugE">
                                            <label width="auto" for="endLocation-typeahead-downshift-container-input" id="endLocation-typeahead-downshift-container-label" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-fXSgeo jcqFHr"
                                                    style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '0', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Where to?
                                            </label>
                                            <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                                <div data-testid="icon-field-prefix-icons" class="sc-gEvEer sc-eqUAAy fQyDwd hIDAJO">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Search__SvgSearch-sc-1osw7jr-0 kHgnEk">
                                                    <path d="M15.5 14h-.8l-.3-.3c1-1.1 1.6-2.6 1.6-4.2C16 5.9 13.1 3 9.5 3S3 5.9 3 9.5 5.9 16 9.5 16c1.6 0 3.1-.6 4.2-1.6l.3.3v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z"></path>
                                                    </svg>
                                                </div>
                                                <Autocomplete id="endLocation-typeahead-downshift-container-input" data-testid="endLocation-typeahead-input" aria-autocomplete="list" 
                                                    aria-labelledby="endLocation-typeahead-downshift-container-label" class="sc-hCPjZK hmtJkv text-start" type='text'
                                                    apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY'  placeholder="Departure From?" name="endLocation" font-size="2,,1" 
                                                    onPlaceSelected={handlePlaceSelected}  onChange={ToursDestination}
                                                    options={{
                                                        types: ['(regions)'],
                                                        componentRestrictions: null,
                                                    }}
                                                    style={{height: '56px', transitionProperty: 'paddingTop, padding-bottom' , transitionDuration: '0.1s'}}>
                                                </Autocomplete>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div width="1,,,,0.3333333333333333" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 kRXxeM eplQeC" style={{width:'25%'}}>
                  <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                    <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                      <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 sc-20db91c2-0 bXyETw gSCCYN">
                        <div color="background.lightest" class="Box-pcln-design-system__sc-n9h3nv-0 hhIGyZ">
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                            <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 kFfTeO">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                <button width="1" color="primary" aria-expanded="false" tabindex="0" type="button"  role="combobox" aria-labelledby="id-1707298637790" aria-invalid="false" data-value="false" 
                                        class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 gCTxcz sc-2e703bfe-1 hyaGGX">
                                  <div height="100%" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gLnKRh EZYWO">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iqzbxV Calendar__SvgCalendar-sc-12aq2xi-0 DXxDd">
                                      <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                                    </svg>
                                    <DatePicker
                                        selected={selectedTourDate}
                                        onChange={handleTourDateChange}
                                        placeholderText='Departing'
                                        dateFormat='dd/MM/yyyy' // Customize date format as needed
                                        // className='form-control text-start select-styling ps-5'
                                    />
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div width="1,,1,,0.5" class="Box-pcln-design-system__sc-n9h3nv-0 hnrfup" style={{width:'20%'}}>
                  <button width="1" type="button" scale="1.02" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 hwEbXr sc-266892e-0 kULWIA" color="primary" onClick={(event) => ToursSearchbtn(event)}>
                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">Edit Search Package</div>
                  </button>
                </div>
            </div>
         </div>
        </>
    );
}

export default ModifyPackageSearch;
