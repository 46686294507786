import React,{useState,useEffect} from 'react'
import alaqsa from '../../Images/Packages/AlAqsa-package.jpg'
import turky from '../../Images/Packages/Turky-packages.jpg'
import umrah from '../../Images/Packages/umrah-package.jpg'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeadset,
  faPersonWalkingLuggage,
  faSackDollar,
  faUmbrellaBeach,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { Hotelapitoken,ApiEndPoint } from '../GlobalData/GlobalData'
function MorePackages () {
  const [t,i18n] = useTranslation();
  const language=i18n.language;
  const [packages,setPackages]=useState([]);
  useEffect(()=>{
    GetPackages();
  },[]);

  const GetPackages = async () => {
    var token = Hotelapitoken();
    var apiendpoint=ApiEndPoint();
    var data = {
      token: token
    }
    try {
      const response = await Axios.post(
        apiendpoint + '/api/latest_packages_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      var packagesCount;
      if (response.data.latest_packages.length !== 0) {
        var umrah = response.data.latest_packages[0].length
        var alaqsa = response.data.latest_packages[1].length
        var turkey = response.data.latest_packages[2].length
        packagesCount = {
          umrahs: umrah,
          alaqsas: alaqsa,
          turkeys: turkey
        }
      }
      setPackages(packagesCount);
    
    } catch (error) {
      console.error('Error:', error)
    }
  };

  return (
    <>
     <div class="container mt-5 mb-5">
        <div class="row align-items-center justify-content-between g-4">

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-5">
            <div class="featuresBox-wrap">
              <div class="featuresBox-icons mb-3">
                <i class="fa-solid fa-sack-dollar fs-1 text-primary"></i>
                <FontAwesomeIcon icon={faSackDollar}/>
              </div>
              <div class="featuresBox-captions">
                <h4 class="fw-bold fs-5 lh-base mb-0">{t('Easy Booking')}</h4>
                <p class="m-0"> {t('Our easy booking process ensures a seamless and efficient reservation experience. Select your preferences and confirm your booking in just a few clicks. Enjoy the convenience of planning your trips effortlessly')}.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-5">
            <div class="featuresBox-wrap">
              <div class="featuresBox-icons mb-3">
                <i class="fa-solid fa-umbrella-beach fs-1 text-primary"></i>
                <FontAwesomeIcon icon={faUmbrellaBeach}/>
              </div>
              <div class="featuresBox-captions">
                <h4 class="fw-bold fs-5 lh-base mb-0">{t('Best Destinations')}</h4>
                <p class="m-0">{t('Immerse yourself in unparalleled beauty and discover top-rated locations that promise memorable moments.Whether you seek relaxation, adventure, or cultural enrichment , our best destinations guarantee an extraordinary journey')}.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-5">
            <div class="featuresBox-wrap">
              <div class="featuresBox-icons mb-3">
                <i class="fa-solid fa-person-walking-luggage fs-1 text-primary"></i>
                <FontAwesomeIcon icon={faPersonWalkingLuggage}/>
              </div>
              <div class="featuresBox-captions">
                <h4 class="fw-bold fs-5 lh-base mb-0">{t('Travel Guides')}</h4>
                <p class="m-0">{t('Dive into rich cultural insights, dining recommendations, and must-see attractions, ensuring every trip is infused with authenticity and excitement. Let our Travel Guides be your trusted companion in exploring the world')}.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-5">
            <div class="featuresBox-wrap">
              <div class="featuresBox-icons mb-3">
                <i class="fa-solid fa-headset fs-1 text-primary"></i>
                <FontAwesomeIcon icon={faHeadset}/>
              </div>
              <div class="featuresBox-captions">
                <h4 class="fw-bold fs-5 lh-base mb-0">{t('Friendly Support')}</h4>
                <p class="m-0">{t('Experience the warmth of our dedicated support team ready to assist you at every step. Our commitment to providing friendly support.Whether you need guidance, or seek assistance, our team is here')}.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className='container '>
        <div className='row'>
          <div className='section-title'>
            {language==='arabic' ?(
               <h2 className='wow animate__animated animate__fadeInUp' data-wow-duration="1s" data-wow-delay="0.3s">
               {t('More than 100 Packages')}
             </h2>
            ):(
              <h2 className='wow animate__animated animate__fadeInUp' data-wow-duration="1s" data-wow-delay="0.3s">
              More than <span>100 Packages</span>
            </h2>
            )}
          </div>
        </div>
        <div className='grid-wrapper'>
          <div className='wow animate__animated animate__slideInLeft wide image-container morepackages-image-container' data-wow-duration="1s" data-wow-delay="0.3s">
            <img src={alaqsa} alt='' />
            <div className='overlay'>
              {' '}
              <div>
                <h5>{t('Al-Aqsa')}</h5>
              </div>
              <div className='bottom-right'>
                <h5>{t('Available Packages')} {packages.alaqsas}</h5>
              </div>
            </div>
          </div>

          <div className='wow animate__animated animate__slideInRight tall image-container morepackages-image-container'data-wow-duration="1s" data-wow-delay="0.3s" >
            <img src={umrah} alt='' />
            <div className='overlay'>
              {' '}
              <div>
                <h5>{t('Umrah Packages')}</h5>
              </div>
              <div className='bottom-right'>
                <h5>{t('Available Packages')} {packages.umrahs}</h5>
              </div>
            </div>
          </div>

          <div className='wow animate__animated animate__slideInLeft wide image-container morepackages-image-container' data-wow-duration="1s" data-wow-delay="0.3s">
            <img src={turky} alt='' />
            <div className='overlay'>
              {' '}
              <div>
                <h5>{t('Turkey')}</h5>
              </div>
              <div className='bottom-right'>
                <h5>{t('Available Packages')} {packages.turkeys}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MorePackages
