import React,{useState,useEffect} from "react";
import { ActivityToken,ApiEndPoint } from "../GlobalData/GlobalData";
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loader";
import moment from "moment";
import Autocomplete from 'react-google-autocomplete'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendar,
  } from '@fortawesome/free-solid-svg-icons'
  import { ActivitiesListing } from "../../Redux/Actions/actions";
  import { useDispatch } from "react-redux";
import Axios from 'axios';
function ActivitySearch(){
  const navigation=useNavigate();
  const dispatch=useDispatch();
    const [selectedActivityDate, setSelectedActivityDate] = useState(null);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedCity, setSelectedCity] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleActivityDateChange = date => {
        setSelectedActivityDate(date)
      };

      let debounceTimeout;

      const fetchOptionsFromAPI = async (inputValue) => {
        var endpoint=ApiEndPoint();
        var token=ActivityToken();
        var data={
            'token':token,
            'location':inputValue,
        }
        try {
         
          const response = await Axios.post(endpoint+'/api/cites_suggestions',data, {
            headers: {
              "Access-Control-Allow-Origin": "*",
            } ,
            
          });      
          return response.data.locations;
        } catch (error) {
          console.error('Error fetching options:', error);
        }
      };
    
      const debouncedFetchOptions = (value) => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(async () => {
          const result = await fetchOptionsFromAPI(value);
          const transformedLocations = result.map((item, index) => ({
            label: item.location,
            value: index, // You can use a unique identifier as the value, for example, item.id if available
          }));
          setOptions(transformedLocations);
        }, 300);
      };
    
      useEffect(() => {
        // Cleanup the debounce function on component unmount
        return () => {
          clearTimeout(debounceTimeout);
        };
      }, []);
    
      const handleInputChange = (newValue) => {
        setInputValue(newValue);
        debouncedFetchOptions(newValue);
      };
    
      const handleChange = (selectedOption) => {
        setSelectedCity(selectedOption);
      };


    const show=async()=>{
      var endpoint=ApiEndPoint();
      var token=ActivityToken();
      var data={
        'token':token,
        'location':selectedCity?.label,
        'start_dates':moment(selectedActivityDate).format('DD-MM-YYYY'),
      }
      setIsLoading(true);
      try {
        
        const response = await Axios.post(endpoint+'/api/search_activities_react',data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          } ,
          
        });
        setIsLoading(false);
        dispatch(ActivitiesListing(response.data));
        navigation('/activities');
      
      } catch (error) {
        // Handle errors here
        setIsLoading(false);
        console.error('Error:', error);
      }
      
    };
    
    return(
        <>
        {isLoading &&(
          <Loading/>
        )}
            <div className='p-3'>
                <div className='row'>
                     <div width="1,,1,,1" data-testid="DASH_TAB_HOTELS_ENDLOCATION" class="sc-gEvEer dQFMGm">
                        <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw">
                            <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                              <div width="1" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="flights.0.startLocation-typeahead-downshift-container-label" aria-controls="typeahead-dropdown" class="Box-pcln-design-system__sc-n9h3nv-0 bXyETw" style={{position: 'relative'}}>
                                <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 sc-20db91c2-0 bXyETw gSCCYN">
                                  <div color="background.lightest" class="Box-pcln-design-system__sc-n9h3nv-0 hhIGyZ">
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK">
                                      <div width="1" class="Box-pcln-design-system__sc-n9h3nv-0 kFfTeO">
                                        <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                          <div class="Box-pcln-design-system__sc-n9h3nv-0 FormField__DisableableBox-pcln-design-system__sc-tx1s9e-0 WjkcY">
                                            <label width="auto" for="flights.0.startLocation-typeahead-downshift-container-input" id="flights.0.startLocation-typeahead-downshift-container-label" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="Label-pcln-design-system__sc-7sa88g-0 ezPCvJ"
                                              style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '0', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Select City</label>
                                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv llNdfK" style={{border:'1px solid rgb(192, 202, 213)', borderRadius:'12px'}}>
                                              <div data-testid="icon-field-prefix-icons" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 danbLM brHOWu">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Search__SvgSearch-sc-1osw7jr-0 kHgnEk">
                                                  <path d="M15.5 14h-.8l-.3-.3c1-1.1 1.6-2.6 1.6-4.2C16 5.9 13.1 3 9.5 3S3 5.9 3 9.5 5.9 16 9.5 16c1.6 0 3.1-.6 4.2-1.6l.3.3v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z"></path>
                                                </svg>
                                              </div>
                                              <Select id="flights.0.startLocation-typeahead-downshift-container-input" data-testid="flights.0.startLocation-typeahead-input" aria-autocomplete="list" aria-labelledby="flights.0.startLocation-typeahead-downshift-container-label" 
                                                      font-size="2,,1" class="Input__StyledInput-pcln-design-system__sc-7wuyrc-0 VtYfX" 
                                                      options={options} onInputChange={handleInputChange} onChange={handleChange} placeholder="Type to search..."
                                                      isSearchable value={selectedCity} isClearable={true} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                     </div>
                    <div width="1,,1,,0.5" class="Box-pcln-design-system__sc-n9h3nv-0 hnrfup">
                        <div width="1" class="sc-gEvEer dsJkWl">
                          <div style={{width: '100%', position: 'relative', zIndex: 'auto'}}>
                            <div width="1" class="sc-gEvEer sc-20db91c2-0 dsJkWl gSCCYN">
                                <div color="background.lightest" class="sc-gEvEer hILmdS">
                                  <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe">
                                    <div width="1" class="sc-gEvEer SjeQr">
                                      <div class="sc-gEvEer PJepm">
                                        <div class="sc-gEvEer sc-uVWWZ kPRUfN jGcugE">
                                          <label for="hotelDates" width="auto" color="text.light" font-size="10" font-weight="700" letter-spacing="0.2px" class="sc-fXSgeo cAIQgh" 
                                                style={{height: '20px', transitionProperty: 'opacity', transitionDuration: '0.1s', opacity: '1', pointerEvents: 'none', position: 'relative', width: 'calc(100% - 40px)'}}>Departure Date
                                          </label>
                                          <div class="sc-gEvEer sc-eqUAAy PJepm evpJHe kpaNAY1 transfer">
                                            <div data-testid="icon-field-prefix-icons" class="sc-gEvEer sc-eqUAAy fQyDwd hIDAJO">
                                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 aLkqs Calendar__SvgCalendar-sc-12aq2xi-0 DXxDd">
                                                <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                                              </svg>
                                            </div>
                                            <DatePicker
                                              style={{border:'0 !important', marginLeft:'30px', height: '56px', paddingTop: '20px', paddingBottom: '8px', transitionProperty: 'paddingTop', transitionDuration: '0.1s'}} 
                                              font-size="2,,1" selected={selectedActivityDate} onChange={handleActivityDateChange} placeholderText='Select Date' dateFormat='dd/MM/yyyy' type='date'
                                              data-datepicker="true" id="hotelDates" data-testid="hotel-date-range" aria-controls="hotel-calander-wrapper" aria-expanded="false" 
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div width="1,,1,,0.5" class="Box-pcln-design-system__sc-n9h3nv-0 hnrfup">
                        <button width="1" type="button" scale="1.02" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 hwEbXr sc-266892e-0 kULWIA" color="primary" onClick={show}>
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">Find Your Activities</div>
                        </button>
                        <div font-size="0" class="Text-pcln-design-system__sc-1xtb652-0 jFIluh">Book Now</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ActivitySearch;