import React,{useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faInfoCircle,
    faStar,
  faGlobe,
  faCalendarCheck,
} from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';
import { useNavigate } from "react-router-dom";
import { CustomerDomainName,ApiEndPoint,ActivityToken } from "../GlobalData/GlobalData";
function ActivityCard({newActivityListing}){
  const [isLoading, setIsLoading] = useState(false);
  var Domainpath=CustomerDomainName();
  var navigation=useNavigate();
const showDetail=(id)=>{
  navigation(`/activity_details/${id}`);
};
 
    return (
        <>
        <div className='filter-page__content'>
            <div className='filter-item-wrapper' id='tours_filter'>
             {newActivityListing.map((item,index)=>(
                <div key={index} className='row'>
                  <div className='col-md-12 mb-4'>
                    <div className='row parent_row ipmBhE hZjqDq fIdRda'>
                      <div className='col-md-3 item-from p-0'>
                        <div height="100%" class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 gLnKRh dgoPHI">
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 eMuljS brHOWu" width="1" height="100%">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 ImageSection__DynamicAbsolute-sc-8eqgic-0 djhuzv cCaSNq cPqfpR">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 djwVDp" width="1">
                                <div aria-label="Click to open popover with more information" type="button" class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv">
                                  <button aria-label="Sign in to save" data-testid="HEART_SAVE_CONTAINER" class="Button__StyledButton-pcln-design-system__sc-uq13ez-0 drkNgV HeartSaveButton__HeartSaveContainer-sc-1o6sggt-0 hItZFh" color="primary">
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kAXPUW">
                                      <div data-testid="ANIMATED_HEART_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 AnimatedHeartIcon__AnimatedHeartContainer-sc-aao2eh-0 djhuzv jEqlGx">
                                        <svg viewBox="0 0 560 560" class="heart-svg">
                                          <path class="heart" transform="translate(25, 25)" d="M256,96.5l-19.7-20.3C186.1,24.3,104.5,15.9,49.7,62.6c-62.8,53.6-66.1,149.8-9.9,207.9l193.5,199.8 c12.5,12.9,32.8,12.9,45.3,0l193.5-199.8c56.3-58.1,53-154.3-9.8-207.9l0,0C407.5,15.9,326,24.3,275.7,76.2L256,96.5z"></path>
                                        </svg>
                                        <span class="heart-burst"></span>
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div style={{borderRadius:'16px 0px 0px 16px'}} class="BackgroundImage-pcln-design-system__sc-1eaij4d-0 ImageDisplay__ClickableImage-sc-1q7kzz5-1 gDxjVM eaVPxX" width="1" data-testid="CLICKABLE_IMAGE_WRAPPER">
                              <div style={{borderRadius:'16px 0px 0px 16px'}} class="InlineImageSwipe__Container-sc-zl6cca-0 kwHIxs">
                                <div style={{borderRadius:'16px 0px 0px 16px'}} class="Box-pcln-design-system__sc-n9h3nv-0 Relative-pcln-design-system__sc-us5x91-0 PhotoSwipe__CarouselContainer-sc-1cx2tme-2 juFVep dgoPHI erKYis" data-testid="CAROUSEL_CONTAINER" width="1" role="region" aria-label="Photo carousel">
                                  <div>
                                    <div class="Box-pcln-design-system__sc-n9h3nv-0 Absolute-pcln-design-system__sc-1sjf63y-0 PhotoCarousel__PhotoCarouselAbsolute-sc-engw3c-0 hLLYkz dpgIZ eNvHXq">
                                      <div class="PhotoCarousel__ImageWrap-sc-engw3c-1 dwDXYb">
                                        <span aria-hidden="true" class="PhotoCarousel__CenterImageHelper-sc-engw3c-2 hLmSyt"></span>
                                        <img class="Image-pcln-design-system__sc-1x3ijma-0 MainImage__StyledMainImage-sc-1wwotm0-0 ebgvjd fhxWfg PhotoCarousel__PositionMainImage-sc-engw3c-3 gqbmxJ hero-image"  src={Domainpath+'/public/images/activites/'+item.featured_image} alt="Exterior View" data-testid="MainImage" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 CarouselPagination__CarouselContainer-sc-9e4onp-0 djhuzv brHOWu dRiUBG PhotoSwipe__CarouselPaginationGradient-sc-1cx2tme-10 esKvPa PhotoSwipe__CarouselPaginationGradient-sc-1cx2tme-10 esKvPa">
                                    <div data-unit-id="CAROUSEL_SLIDER" class="CarouselPagination__StyledCarousel-sc-9e4onp-1 gzvUFf">
                                      <form>
                                        <div data-unit-id="PAGINATION_DOTS_CONTAINER" class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 CarouselPagination__StyledFlex-sc-9e4onp-2 djhuzv ipCoSW mDstq">
                                          <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                            <button aria-label="Go to Image #1" aria-current="true" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 gfMkOK">
                                              <div color="text.lightest" width="8px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 cefYII hXamFu dlexSF"></div>
                                            </button>
                                          </div>
                                          <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                            <button aria-label="Go to Image #2" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                              <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                            </button>
                                          </div>
                                          <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                            <button aria-label="Go to Image #3" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                              <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                            </button>
                                          </div>
                                          <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                            <button aria-label="Go to Image #4" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                              <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                            </button>
                                          </div>
                                          <div class="PaginationDot__StyledContainer-sc-1ikc0tk-0 gffuWP">
                                            <button aria-label="Go to Image #5" aria-current="false" data-testid="PAGINATION_RADIO" type="button" class="PaginationDot__StyledButton-sc-1ikc0tk-1 ggdXfq">
                                              <div color="text.lightest" width="6px" class="Box-pcln-design-system__sc-n9h3nv-0 Card-pcln-design-system__sc-1iw8zza-0 PaginationDot__StyleDot-sc-1ikc0tk-2 kSfPMC hXamFu gRAOWx"></div>
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6 px-3 py-2'>
                        <h5 className='card-title mt-2'>
                          <a
                           onClick={()=>showDetail(item.id)}
                            className='p-card-title'
                          >
                           {item.title}
                          </a>
                        </h5>
                        <h6 className='departure-date my-2'>
                         {' '}
                          <FontAwesomeIcon icon={faGlobe} />{' '}
                         {item.location}
                        </h6>
                        <div class='row'>
                          <div class='single-feature-titles'>
                            <p style={{fontSize:'13px'}}>{item.activity_content}</p>
                          </div>
                        </div>
                        <hr style={{opacity:'1', marginTop:'50px'}} />
                        <p className='card-star'>
                          {item.starts_rating === '' ? (
                            <span className='fw-bold'>No Rating</span>
                          ) : (
                            Array(item.starts_rating )
                              .fill(0)
                              .map((_, index) => (
                                <i key={index} className='fa fa-star'>
                                  <FontAwesomeIcon icon={faStar} />
                                </i>
                              ))
                          )}
                          (Activity Hours : {item.duration})
                        </p>
                        <div class='item-address' style={{ color: 'green' }}>
                          <i class='awe-icon awe-icon-marker-2'><FontAwesomeIcon icon={faCalendarCheck} /></i>{' '}
                           {item.activity_date}{' '}
                        </div>
                      </div>
                      <div className='col-md-3 casfcL p-2'>
                        <div className='text-center card-price-section hIzCYw fUSPHi'>
                          <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv iysiIk MinRateSection__PaddedMinRateMerchandising-sc-18x9jjj-0 fRHKIm MinRateSection__PaddedMinRateMerchandising-sc-18x9jjj-0 fRHKIm">
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 djhuzv kJGIQs">
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 djhuzv MinRateMerchandising__PaddedMerchandisingTypes-sc-1tcss2r-0 XehgS"></div>
                              <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 gHDiTU brHOWu" style={{textAlign:'center'}}>
                                <div color="pricePrimary.base" font-weight="bold" class="ijknVs text-center"> 10% Off</div>
                              </div>
                            </div>
                            <div class="Box-pcln-design-system__sc-n9h3nv-0 Flex-pcln-design-system__sc-1yrtwh8-0 ezJlZN dBEOEE">
                              <div font-size="0" color="text.light" data-unit-id="SECTION_SUBTITLE" class="Text-pcln-design-system__sc-1xtb652-0 SavingsDisplay__SubTitleText-sc-1o04y33-0 HkSpC csvZTM">Savings last for a limited time only</div>
                            </div>
                          </div>
                          <div className="ggTnOm pb-2">
                            <div className='price text-center p-card-price'>
                              <h6 className="mt-1 mb-1"><super>{item.currency_symbol} {item.sale_price}</super></h6>
                            </div>
                            <button onClick={()=>showDetail(item.id)} className='my-1 kOMYfA'> Book Now</button>
                            <div style={{ fontSize: '11px' }}>
                              <FontAwesomeIcon  color='#bd1c1cc9' icon={faInfoCircle} />{' '}
                              Inclusive of VAT and Taxes
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
    )
}

export default ActivityCard;